import {defineStore} from 'pinia'
import pkg from '@/package.json'

export const useBoardsFilterStore = defineStore('boardsFilter', {
    // convert to a function
    state: () => ({
        staticOverview: {
            version: '',
            period: '',
            users: [],
        },
        simpleDealList: {
            version: '',
            period: '',
            users: [],
        },
        tripleDealList: {
            version: '',
            period: '',
            users: [],
            eventTypes: [],
        }
    }),

    getters: {
        isOutdated: (state) => {
            return (boardName) => {
                return state[boardName]?.version !== pkg.version
            }
        }
    },

    actions: {
        getFilter() {
            let boardNames = Object.keys(this.$state)

            boardNames.forEach(boardName => {
                let boardFilter = localStorage.getItem(boardName)
                if (boardFilter) {
                    this[boardName] = JSON.parse(boardFilter)
                    //migrate old filter
                    if (this[boardName]?.period?.value?.includes(';')) {
                        console.warn("filter outdated, migrating...")
                        const migrationMap = new Map([
                            ['Tag' , 'day'],
                            ['Woche', 'week'],
                            ['Monat', 'month'],
                            ['Quartal', 'quarter'],
                            ['Jahr', 'year'],
                            ['Gesamt', 'all'],
                        ])
                        this[boardName].period.value = migrationMap.get(this[boardName].period.option) || ''
                        this[boardName].version = pkg.version
                        localStorage.setItem(boardName, JSON.stringify(this[boardName]))
                    }
                }
            })
        },

        setFilter(boardName, filter, value) {
            this[boardName][filter] = value
            this[boardName].version = pkg.version
            localStorage.setItem(boardName, JSON.stringify(this[boardName]))
        }
    }
})